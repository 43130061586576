import React, { useState, useEffect } from "react";
import Header from "../maincomponents/Header";
import Footer from "../maincomponents/Footer";
import { Link } from "react-router-dom";

import { fetchCategoryList, Contact } from "../utils/ApiService";
import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import Spinner from "./main/Spinner";
import { Helmet } from "react-helmet";

const Home = () => {
  //------------------------CATEGORY LISTS---------------------------------

  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCategoryList = async () => {
      try {
        const data = await fetchCategoryList();
        setCategory(data);
        setLoading(false);
      } catch (error) {
        console.error("Error Fetching Category", error);
        setLoading(false);
      }
    };

    getCategoryList();
  }, []);
  useEffect(() => {
    const getCategoryList = async () => {
      try {
        const data = await fetchCategoryList();
        setCategory(data);
      } catch (error) {
        console.error("Error Fetching Category", error);
      }
    };

    getCategoryList();
  }, []);
  //------------------------GET A QUICK REPLY---------------------------------
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState("");

  const handleContact = async (event) => {
    event.preventDefault();
    const contactData = {
      name: name,
      message: message,
      country: selected,
      contact: contact,
      email: email,
    };

    try {
      const response = await Contact(contactData);
      swal(
        "Good job!",
        "Thank you for contacting us, we will connect to you shortly",
        "success"
      );
      setName("");
      setMessage("");
      setSelected("");
      setContact("");
      setEmail("");
    } catch (error) {
      // console.error('Error during user registration', error);
      swal("Oops!", "Please Login and try again.", "error");
    }
  };
  return (
    <>
      {loading && <Spinner />}
      <Header />
      <Helmet>
        <title>Saaskin | Home</title>
        <meta
          name="description"
          content="Saaskin offers advanced medical products and healthcare solutions. Discover cutting-edge innovations for improved patient care and health outcomes."
        />
        <meta
          name="keywords"
          content="Saaskin, medical products, healthcare solutions, patient care, medical innovations, healthcare technology, medical devices, health solutions"
        />
        <meta property="og:title" content="Saaskin | Home" />
        <meta
          property="og:description"
          content="Explore Saaskin's advanced medical products and innovative healthcare solutions for better patient care."
        />
        <meta property="og:url" content="https://react.bwsoft.in" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="./images/saaskin_title_logo.png" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "MedicalOrganization",
        "name": "Saaskin",
        "url": "https://react.bwsoft.in",
        "logo": "./images/saaskin_title_logo.png",
        "sameAs": [
          "https://www.facebook.com/saaskin",
          "https://twitter.com/saaskin",
          "https://www.linkedin.com/saaskin"
        ],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "275 /184, First Floor, Office No 2, Golden Enclave, Periyar Evr
                Salai, P.h. Road, Kilpauk,",
          "addressLocality": "Chennai",
          "addressRegion": "TN",
          "postalCode": "600010",
          "addressCountry": "IN"
        },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9940116677",
          "contactType": "Customer Service",
          "areaServed": "IN",
          "availableLanguage": "English"
        },
        "description": "Saaskin offers advanced medical products and healthcare solutions for better patient care."
      }
    `}
        </script>
        <link rel="canonical" href="https://react.bwsoft.in" />
      </Helmet>

      <div className="container" style={{ paddingTop: "30px" }}>
        <h1 style={{ textAlign: "center" }}>Our Product Range</h1>
        <div className="row" style={{ paddingTop: "30px" }}>
          {category.map((cat, index) => (
            <div key={cat.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <Link
                to={`/subcategory/${cat.id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="card">
                  <img
                    className="card-img-top"
                    src={cat.product_image}
                    alt={cat.name}
                    style={{ height: "200px", objectFit: "contain" }}
                  />
                  <div className="card-body">
                    <p className="card-text" style={{ textAlign: "center" }}>
                      {cat.name}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div style={{ padding: "40px", fontWeight: "10px", fontSize: "18px" }}>
        <form onSubmit={handleContact}>
          <h2 style={{ textAlign: "center" }}>Get a Quick Reply</h2>
          <div className="row">
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Country</label>
              <div
                style={{
                  border: "1px solid",
                  height: "44px ",
                  borderRadius: "5px",
                }}
              >
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  searchable
                  searchPlaceholder="Search Countries"
                  placeholder="Select Your Country"
                />
              </div>
            </div>
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Mobile Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Valid Number"
                id="mobile"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div className="col-md-12" style={{ padding: "15px 30px" }}>
              <label>Message</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Message"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div
              className="col-md-12"
              style={{ textAlign: "center", padding: "15px 30px" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%", backgroundColor: "#5bc0de" }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Home;
